<template>
  <section class="container text-white mt-5">
    <article class="row">
      <div class="col">
        <h2 id="aCookies">Aviso de Cookies</h2>
        <br />

        <p >
          En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la
          Sociedad de la Información y Comercio Electrónico informamos al
          Usuario de la utilización de cookies en la web de
          “www.matedeveloop.com”.
        </p>
        <br /><br />

        <h3>¿Qué son las cookies, scripts, o baliza web?</h3>
        <br />
        <p>
          Una <span>cookie</span> es un fichero que se descarga en su ordenador
          al acceder a determinadas páginas web. Las cookies permiten a una
          página web, entre otras cosas, almacenar y recuperar información sobre
          los hábitos de navegación de un usuario o de su equipo y, dependiendo
          de la información que contengan y de la forma en que utilice su
          equipo, pueden utilizarse para reconocer al usuario.
        </p>
        <br />
        <p>
          Un <span>script</span> es un fragmento de código de programación cuya
          función es hacer nuestra web funcional e interactiva. Este código se
          ejecuta en el servidor o en el dispositivo del usuario.
        </p>
        <br />
        <p>
          <span>Una baliza web</span> (o etiqueta de pixel) es una pequeña e
          invisible pieza de texto o imagen en una web que se utilizapara hacer
          segimiento del tráfico en la web.
        </p>
        <br /><br />

        <h3>¿Qué tipos de cookies utiliza esta página web?</h3>
        <br />
        <p>Esta página web utiliza los siguientes tipos de cookies:</p>
        <br />

        <p>
          <span> Cookies de análisis:</span> Son aquéllas que bien tratadas por
          nosotros o por terceros, nos permiten cuantificar el número de
          usuarios y así realizar la medición y análisis estadístico de la
          utilización que hacen los usuarios del servicio ofertado. Para ello se
          analiza su navegación en nuestra página web con el fin de mejorar la
          oferta de productos o servicios que le ofrecemos.
        </p>
        <br />

        <p>
          <span>Cookies técnicas:</span> Son aquellas que permiten al usuario la
          navegación a través del área restringida y la utilización de sus
          diferentes funciones, como por ejemplo, llevar a cambio el proceso de
          compra de un artículo. Al colocar cookies funcionales, facilitamos la
          visita y uso de nuestra web. De esta manera, no es necesario
          introducir repetidamente la misma información cuando se visita nuestra
          web y, por ejemplo, los artículos permanecen en la cesta de la compra
          hasta que el usuario finalice la compra. La normativa nos permite
          colocar estas cookies sin tu consentimiento.
        </p>
        <br />

        <p>
          <span>Cookies de personalización:</span>Son aquellas que permiten al
          usuario acceder al servicio con algunas características de carácter
          general predefinidas en función de una serie de criterios en el
          terminal del usuario como por ejemplo serian el idioma o el tipo de
          navegador a través del cual se conecta al servicio.
        </p>

        <p>
          <span>Cookies publicitarias:</span> Son aquéllas que, bien tratadas
          por esta web o por terceros, permiten gestionar de la forma más eficaz
          posible la oferta de los espacios publicitarios que hay en la página
          web, adecuando el contenido del anuncio al contenido del servicio
          solicitado o al uso que realice de nuestra página web. Para ello
          podemos analizar sus hábitos de navegación en Internet y podemos
          mostrarle publicidad relacionada con su perfil de navegación.
        </p>
        <br />

        <p>
          <span>Cookies de publicidad comportamental</span> : Son aquellas que
          permiten la gestión, de la forma más eficaz posible, de los espacios
          publicitarios que, en su caso, el editor haya incluido en una página
          web, aplicación o plataforma desde la que presta el servicio
          solicitado. Este tipo de cookies almacenan información del
          comportamiento de los visitantes obtenida a través de la observación
          continuada de sus hábitos de navegación, lo que permite desarrollar un
          perfil específico para mostrar avisos publicitarios en función del
          mismo.
        </p>
        <br /><br />

        <table class="resp">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Descripcion</th>
              <th scope="col">Tipo</th>
              <th scope="col">Caducidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga, _ga (alfanúmerica)</td>
              <td>
                Cookies de Google Analytics. Informes estadísticos sobre como
                los usuarios encuentran la página web y cómo la utilizan:
                páginas visitadas, tiempo de estancia, tipo de navegador… _ga y
                _gid: Se usan para distinguir a los usuarios. _gat: Se usa para
                limitar el porcentaje
              </td>
              <td>Analíticas, de terceros</td>
              <td>_ga: 2 años</td>
            </tr>
          </tbody>
        </table>

        <h3>Desactivar las cookies.</h3>
        <br />
        <p>
          El Sitio Web puede utilizar cookies instaladas por terceros para
          realizar un análisis estadístico sobre la navegación de los usuarios,
          o mostrar contenido publicitario personalizado. Por ejemplo, cookies
          de RRSS o de Google. Tenga en cuenta que, si acepta las cookies de
          terceros, deberá eliminarlas desde las opciones del navegador o desde
          el sistema ofrecido por el propio tercero.
        </p>
        <p>
          Puede usted permitir, bloquear o eliminar las cookies instaladas en su
          equipo mediante la configuración de las opciones del navegador
          instalado en su ordenador.
        </p>
        <br /><br />

        <p>
          En la mayoría de los navegadores web se ofrece la posibilidad de
          permitir, bloquear o eliminar las cookies instaladas en su equipo.
        </p>
        <br />

        <p>
          A continuación puede acceder a la configuración de los navegadores
          webs más frecuentes para aceptar, instalar o desactivar las cookies:
        </p>
        <br />

        <p>
          <a
            rel="nofollow"
            style="color: #ffde94"
            href="https://support.google.com/chrome/answer/95647?hl=es"
            target="_blank"
            >Configurar cookies en Google Chrome</a
          >
        </p>

        <p>
          <a
            rel="nofollow"
            style="color: #ffde94"
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            >Inhabilitar Google Analytics</a
          >
        </p>

        <p>
          <a
            rel="nofollow"
            style="color: #ffde94"
            href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
            target="_blank"
            >Configurar cookies en Microsoft Internet Explorer</a
          >
        </p>

        <p>
          <a
            rel="nofollow"
            style="color: #ffde94"
            href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"
            target="_blank"
            >Configurar cookies en Mozilla Firefox</a
          >
        </p>

        <p>
          <a
            rel="nofollow"
            style="color: #ffde94"
            href="https://support.apple.com/es-es/HT201265"
            target="_blank"
            >Configurar cookies en Safari (Apple)</a
          >
        </p>
        <br /><br />

        <h3>Cookies de terceros.</h3>
        <br />

        <p>
          Esta página web utiliza servicios de terceros para recopilar
          información con fines estadísticos y de uso de la web o con fines
          publicitarios personalizados.
        </p>

        <p>
          En concreto, usamos los servicios de Google Adsense y de Google
          Analytics para nuestras estadísticas y publicidad. Algunas cookies son
          esenciales para el funcionamiento del sitio.
        </p>

        <p>
          También, se utilizan enlaces a programas de afiliación comercial como
          Amazon, con políticas de privacidad ajenas, a los cuales puede acceder
          para realizar compras y decidir si acepta o no dichas políticas.
        </p>

        <h3>Advertencia sobre eliminar cookies.</h3>
        <br />

        <p>
          Usted puede eliminar y bloquear todas las cookies de este sitio, pero
          parte del sitio no funcionará o la calidad de la página web puede
          verse afectada.
        </p>

        <p>
          Si tiene cualquier duda acerca de nuestra política de cookies, puede
          contactar con esta página web a través de nuestros canales de
          Contacto.
        </p>

        <h3>Consentimiento y configuración de cookies</h3>
        <br />

        <p>
          Cuando visites nuestra web por primera vez, te mostraremos una ventana
          emergente con una explicación sobre las cookies (“primera capa”). Tan
          pronto como hagas clic en el botón de aceptación, aceptas que usemos
          las categorías de cookies y plugins que has seleccionado en la ventana
          emergente, tal y como se describe en esta política de cookies. Puedes
          desactivar el uso de cookies a través de tu navegador, pero, por
          favor, ten en cuenta que nuestra web puede dejar de funcionar
          correctamente si lo haces.
        </p>
      </div>
    </article>
  </section>
</template>

<script setup>
import { onBeforeRouteUpdate } from 'vue-router';

const pageMeta = {
  title: 'Cookies',
  description: 'Informacion de cookies',
  keywords: 'dialisis, dialisis peritoneal, sintomas, tratamientos, nutricion en dialisis, hiperpotasemia, ¿que es la dialisis?, sodio, fosforo'
};

// Actualizar las metaetiquetas y el título antes de cambiar de ruta
onBeforeRouteUpdate((to, from, next) => {
  updatePageMeta(to.meta);
  next();
});

// Función para actualizar las metaetiquetas y el título
function updatePageMeta(meta) {
  const head = document.head || document.getElementsByTagName('head')[0];

  // Actualizar el título
  const title = document.title;
  document.title = meta.title || title;

  // Actualizar las metaetiquetas
  const metaTags = head.getElementsByTagName('meta');
  for (let i = 0; i < metaTags.length; i++) {
    const tag = metaTags[i];
    if (tag.getAttribute('name') === 'description') {
      tag.content = meta.description || '';
    }
    if (tag.getAttribute('name') === 'keywords') {
      tag.content = meta.keywords || '';
    }
    if (tag.getAttribute('name') === 'robots') {
      tag.content = 'noindex'; // Agregar la etiqueta noindex
    }
  }

  // Si la etiqueta robots no existe, agregarla al head
  const robotsTag = document.createElement('meta');
  robotsTag.name = 'robots';
  robotsTag.content = 'noindex';
  head.appendChild(robotsTag);
}

// Configurar las metaetiquetas y el título inicialmente
updatePageMeta(pageMeta);
</script>

<style scoped>
table {
  width: 100%;
  background: white;
  margin-bottom: 1.25em;
  border: solid 1px #dddddd;
  border-collapse: collapse;
  border-spacing: 0;
}

table tr th,
table tr td {
  padding: 0.5625em 0.625em;
  font-size: 0.875em;
  color: #222222;
  border: 1px solid #dddddd;
}

table tr.even,
table tr.alt,
table tr:nth-of-type(even) {
  background: #f9f9f9;
}

@media (max-width: 768px) {
  table.resp,
  .resp thead,
  .resp tbody,
  .resp tr,
  .resp th,
  .resp td,
  .resp caption {
    display: block;
  }

  table.resp {
    border: none;
  }

  .resp thead tr {
    display: none;
  }

  .resp tbody tr {
    margin: 1em 0;
    border: 1px solid #2ba6cb;
  }

  .resp td {
    border: none;
    border-bottom: 1px solid #dddddd;
    position: relative;
    padding-left: 45%;
    text-align: left;
  }

  .resp tr td:last-child {
    border-bottom: 1px double #dddddd;
  }

  .resp tr:last-child td:last-child {
    border: none;
  }

  .resp td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  td:nth-of-type(1):before {
    content: "Nombre";
  }

  td:nth-of-type(2):before {
    content: "Descripcion";
  }

  td:nth-of-type(3):before {
    content: "Tipo";
  }

  td:nth-of-type(4):before {
    content: "Caducidad";
  }
}
</style>
