<template>
    <section class="container mt-5 text-white">
        <article class="row">
            <div class="col">
                <p>
      “ladialisis.com” se encuentra profundamente comprometido con el cumplimiento de la normativa española y
      europea de protección de datos de carácter personal, y garantiza el cumplimiento íntegro de las obligaciones
      dispuestas,
      así como la implementación de las medidas de seguridad dispuestas en el
      Reglamento General de Protección de Datos (RGPD) (UE) 2016/679, de 27 de abril,
      y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales
      y garantía de los derechos digitales (LOPD y GDD, en adelante LOPD). <br>
      De conformidad con estas normativas, informamos que la utilización de nuestra web puede requerir que se
      faciliten ciertos datos personales a través de formularios de contacto, o mediante el envío de emails,
      y que éstos serán objeto de tratamiento por “ladialisis.com”, Responsable del tratamiento, cuyos datos son:
      <br><br>

      <span> DATOS IDENTIFICATIVOS</span><br>
      Nombre: José Luis Díaz García <br>
      Actividad: Salud 
      Teléfono: 691949043 <br>
      Domicilio: Apartado de Correos nº4, <br> Oficina de Correos Móstoles OP <br> Avda de Portugal 49 <br> 28931
      Móstoles (Madrid) <br><br>
      e-mail: enfermeriaendifusion@ladialisis.com<br><br>


      <span> OBTENCIÓN Y TRATAMIENTO DE LOS DATOS</span><br><br>

      Un dato personal es cualquier información relativa a una persona: nombre, email, domicilio, teléfono, NIF/NIE...
      Adicionalmente, cuando un Usuario visita nuestro sitio web, determinada información se almacena automáticamente
      por
      motivos técnicos, como la dirección IP asignada por su proveedor de acceso a Internet. Concretamente este sitio
      web
      utiliza Google Analytics para analisis de tráfico web con el fin de mejorar sus contenidos. <br>

      “ladialisis.com”, como Responsable del Tratamiento, tiene el deber de informar a los Usuarios de su
      sitio web acerca de la recogida de datos de carácter personal que pueden llevarse a cabo,
      bien sea mediante el envío de correo electrónico o al cumplimentar los formularios incluidos en el sitio web. <br>

      Se obtendrán únicamente los datos precisos para poder responder adecuadamente a la
      petición de información realizada por el Usuario. Los datos recabados son identificativos y
      corresponden a un mínimo razonable para poder llevar a término la consulta o petición realizada.
      En particular, este sitio web utiliza Google Analytics para analisis de tráfico web con el fin de mejorar
      sus contenidos y experiencia de usuario. <br>

      <span>Formularios de email</span> <br>
      Finalidad: Dar contestación a su solicitud de información realizada a través de nuestro/s formulario/s de
      contacto. <br>
      Legitimación: La base jurídica que legitima este tratamiento es el consentimiento del Usuario,
      que podrá revocar en cualquier momento. <br>
      Cesión de datos: Los datos personales serán tratados a través de servidores gestionados por Hostinger,
      que tendrá la consideración de Encargado del Tratamiento. <br><br>


      <span>MENORES DE EDAD</span><br><br>

      Solo podrán aportar datos personales en este sitio web las personas mayores de 14 años.
      Según obliga la LOPD y GDD, en caso de menores de 14 años, será condición obligatoria el
      consentimiento de sus padres o tutores para que podamos tratar sus datos personales. <br>
      Por otra parte, solo podrán contratar nuestros servicios las personas mayores de 18 años.
      En caso de menores de 18 años, será condición obligatoria el consentimiento de sus padres o
      tutores legales para que podamos prestar los servicios ofertados, salvo que el menor esté emancipado. <br><br>

      <span>MEDIDAS DE SEGURIDAD</span><br><br>

      Se informa a los Usuarios de la web de “ladialisis.com” de que se han adoptado las medidas de seguridad,
      técnicas y organizativas a nuestro alcance para evitar la pérdida, mal uso, alteración,
      acceso no autorizado y robo de los datos, y que garantizan así la confidencialidad, integridad y
      calidad de la información contenida en las mismas, de acuerdo con lo establecido en la normativa vigente
      en materia de protección de datos. <br>
      Los datos personales que se recogen en los formularios son objeto de tratamiento,
      únicamente, por parte del personal de “ladialisis.com” o de los Encargados del Tratamiento designados.
      El Sitio Web de “ladialisis.com” cuenta además con un cifrado SSL, que permite al Usuario el envío seguro de sus
      datos
      personales a través de los formularios de contacto o registro del sitio web. <br><br>

      <span>VERACIDAD DE LOS DATOS</span><br><br>

      El Usuario manifiesta que todos los datos facilitados por él son ciertos y
      correctos y se compromete a mantenerlos actualizados.
      El Usuario responderá de la veracidad de sus datos y será el único responsable de
      cuantos conflictos o litigios pudieran resultar por la falsedad de los mismos. Es importante que,
      para que podamos mantener los datos personales actualizados, el Usuario informe a “ladialisis.com”
      siempre que haya habido alguna modificación en los mismos.
      <br><br>

      <span>CESIÓN DE LOS DATOS</span><br><br>

      “ladialisis.com” no cederá ni comunicará a ningún tercero tus datos, excepto en los casos
      legalmente previstos o cuando sea estrictamente necesario para la prestación de un servicio.
      Concretamente, los datos podrán ser cedidos a: <br>
      -Proveedores de servicios tecnológicos o informáticos <br>
      -Proveedores de servicios de pago <br>
      -Empresas de mensajería y paquetería <br>
      -Terceros o intermediarios, en calidad de prestadores de servicios, que operen en nuestro
      propio nombre (gestoría, asesoría…). <br><br>
      Las cesiones de datos se producirán respetando la más estricta confidencialidad,
      empleando las medidas necesarias, tales como la firma de contratos de confidencialidad,
      o la adhesión a sus políticas de privacidad establecidas en sus respectivas páginas web.
      El Usuario podrá negarse a la cesión de sus datos a los Encargados del Tratamiento, mediante petición escrita,
      por cualquiera de los medios anteriormente referenciados. <br>
      Además, en aquellos casos en que sea necesario, los datos de Clientes podrán ser cedidos a
      determinados organismos, en cumplimiento de una obligación legal: Agencia Tributaria Española,
      Inspección de Trabajo, etc. <br><br>

      <span>EJERCICIO DE DERECHO DEL USUARIO</span><br><br>

      La LOPD y el RGPD conceden a los interesados la posibilidad de ejercer una serie de derechos
      relacionados con el tratamiento de sus datos personales. Para ello, el Usuario deberá dirigirse,
      aportando documentación que acredite su identidad (DNI o pasaporte), mediante correo electrónico a
      <span>enfermeriaendifusion@ladialisis.com.net</span> , o bien mediante comunicación escrita a la dirección que
      aparece en nuestro Aviso Legal. Dicha comunicación deberá reflejar la siguiente información:
      nombre y apellidos del Usuario, el objeto de la solicitud, el domicilio y los datos acreditativos. <br>
      El ejercicio de derechos deberá ser realizado por el propio Usuario. No obstante,
      podrán ser ejecutados por una persona autorizada como representante legal del Usuario,
      aportándose la documentación que acredite dicha representación.
      El Usuario podrá solicitar el ejercicio de los derechos siguientes: <br><br>

      -Derecho a solicitar el acceso a los datos personales, que es el derecho a obtener
      información sobre si sus propios datos de carácter personal están siendo objeto de tratamiento,
      la finalidad del tratamiento que, en su caso, se esté realizando,
      así como la información disponible sobre el origen de dichos datos y las comunicaciones realizadas o
      previstas de los mismos. <br>

      -Derecho a solicitar su rectificación, en caso de que los datos personales sean incorrectos o inexactos,
      o supresión de los datos que resulten ser inadecuados o excesivos. <br>

      -Derecho a solicitar la limitación de su tratamiento, en cuyo caso únicamente serán conservados
      por “ladialisis.com” los datos estrictamente necesarios para el ejercicio o la defensa de reclamaciones. <br>

      -Derecho a oponerse al tratamiento: se refiere al derecho del interesado a que no se lleve a cabo el tratamiento
      de sus datos personales o se cese en el mismo en los supuestos en que no sea necesario su
      consentimiento para el tratamiento, que se trate de ficheros de prospección comerciales o
      que tengan la finalidad de adoptar decisiones referidas al interesado y basadas únicamente en el tratamiento
      automatizado de sus datos, salvo que por motivos legítimos o el ejercicio o la defensa de posibles
      reclamaciones se tengan que seguir tratando. <br>

      -Derecho a la portabilidad de los datos: en caso de que quiera que sus datos sean tratados por otra empresa,
      “ladialisis.com” le facilitará la portabilidad de sus datos en formato exportable. <br>
      En el caso de que se haya otorgado el consentimiento para alguna finalidad específica, el Usuario
      tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del
      tratamiento basado en el consentimiento previo a su retirada. <br>

      Nos comprometemos a ejecutar todos estos derechos dentro del plazo legal máximo (1 mes).
      Si un Usuario considera que hay un problema con la forma en que “ladialisis.com” está manejando sus datos,
      puede dirigir sus reclamaciones al Responsable de Seguridad o a la autoridad de protección de datos que
      corresponda,
      siendo la Agencia Española de Protección de Datos la indicada en el caso de España. <br><br>


      <span>CONSERVACIÓN DE LOS DATOS</span><br><br>

      Los datos de carácter personal de los Usuarios que usen el formulario de contacto o que nos envíen un email
      solicitando información serán tratados durante el tiempo estrictamente necesario para atender a la solicitud
      de información, o hasta que se revoque el consentimiento otorgado. <br>
      Los datos de carácter personal de los Clientes serán tratados hasta que finalice la relación contractual.
      El período de conservación de los datos personales será el mínimo necesario, pudiendo mantenerse hasta: <br><br>
      -4 años: Ley sobre Infracciones y Sanciones en el Orden Social
      (obligaciones en materia de afiliación, altas, bajas, cotización, pago de salarios…); Arts. 66 y sig.
      Ley General Tributaria (libros de contabilidad…) <br>
      -5 años: Art. 1964 Código Civil (acciones personales sin plazo especial) <br>
      -6 años: Art. 30 Código de Comercio (libros de contabilidad, facturas…) <br>
      -10 años: Art. 25 Ley de Prevención del Blanqueo de Capitales y Financiación del Terrorismo. <br>
      -Sin plazo: datos desagregados y anonimizados. <br><br>


      <span>REDES SOCIALES</span><br><br>

      “ladialisis.com” cuenta con perfil en algunas de las principales redes sociales de Internet (Instagram),
      reconociéndose en todos los casos Responsable del tratamiento de los datos de sus seguidores, fans, suscriptores,
      comentaristas y otros perfiles de Usuarios (en adelante, seguidores) publicados por “ladialisis.com”. <br>
      La finalidad del tratamiento de datos por parte de “ladialisis.com”, cuando la ley no lo prohíba, será la de
      informar a sus seguidores sobre sus actividades y ofertas, por cualquier vía que la red social permita,
      así como prestar servicio personalizado de atención al cliente. La base jurídica que legitima este tratamiento
      será el consentimiento del interesado, que podrá revocar en cualquier momento. <br>
      En ningún caso “ladialisis.com” extraerá datos de las redes sociales, a menos que se obtuviera puntual y
      expresamente el consentimiento del Usuario para ello (por ejemplo, para la realización de un concurso). <br><br>

      <span>CONFIDENCIALIDAD</span><br><br>

      La información suministrada por el Usuario tendrá, en todo caso, la consideración de confidencial,
      sin que pueda ser utilizada para otros fines distintos a los aquí descritos. “ladialisis.com” se obliga a
      no divulgar ni revelar información sobre las pretensiones del Usuario, los motivos del asesoramiento solicitado
      o la duración de su relación con éste. <br> <br>

      <span>VALIDEZ</span><br><br>

      Esta política de privacidad y de protección de datos ha sido redactada por ExpertosLOPD®,
      empresa de protección de datos, a día 10 de November de 2022, y podrá variar en función de los
      cambios de normativa y jurisprudencia que se vayan produciendo, siendo responsabilidad del titular
      de los datos la lectura del documento actualizado, en orden a conocer sus derechos y obligaciones
      al respecto en cada momento. <br>

    </p>
            </div>
        </article>
    </section>
</template>

<script setup>
import { onBeforeRouteUpdate } from 'vue-router';

const pageMeta = {
  title: 'Privacidad',
  description: 'Politica de privacidad',
  keywords: 'dialisis, dialisis peritoneal, sintomas, tratamientos, nutricion en dialisis, hiperpotasemia, ¿que es la dialisis?, sodio, fosforo'
};

// Actualizar las metaetiquetas y el título antes de cambiar de ruta
onBeforeRouteUpdate((to, from, next) => {
  updatePageMeta(to.meta);
  next();
});

// Función para actualizar las metaetiquetas y el título
function updatePageMeta(meta) {
  const head = document.head || document.getElementsByTagName('head')[0];

  // Actualizar el título
  const title = document.title;
  document.title = meta.title || title;

  // Actualizar las metaetiquetas
  const metaTags = head.getElementsByTagName('meta');
  for (let i = 0; i < metaTags.length; i++) {
    const tag = metaTags[i];
    if (tag.getAttribute('name') === 'description') {
      tag.content = meta.description || '';
    }
    if (tag.getAttribute('name') === 'keywords') {
      tag.content = meta.keywords || '';
    }
    if (tag.getAttribute('name') === 'robots') {
      tag.content = 'noindex'; // Agregar la etiqueta noindex
    }
  }

  // Si la etiqueta robots no existe, agregarla al head
  const robotsTag = document.createElement('meta');
  robotsTag.name = 'robots';
  robotsTag.content = 'noindex';
  head.appendChild(robotsTag);
}

// Configurar las metaetiquetas y el título inicialmente
updatePageMeta(pageMeta);
</script>

<style scoped>
</style>