<template>
  <section class="container text-white mt-5">
    <article class="row">
      <div class="col">
        <h2 id="aAviso">Aviso legal</h2>
        <p>
          En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de
          Servicios de la Sociedad de la Información y del Comercio Electrónico
          (LSSICE), José Luis Díaz García (en adelante, “ladialisis.com”),
          responsable de esta web, pone a disposición de los Usuarios la
          presente información, para definir sus Condiciones de Uso.
          Adicionalmente a los contenidos aquí expuestos, los aspectos
          específicos relacionados con la protección de los datos personales y
          la privacidad de los usuarios de esta web se desarrollan en las
          páginas de:
          <router-link
                  :to="{ name: 'privacidad' }"
                  class="link-warning m-2 text-white"
                  >Politica de Privacidad</router-link> y
          <router-link
                  :to="{ name: 'cookies' }"
                  class="link-warning m-2 text-white"
                  >Politica de Cookies</router-link>
          <br />

          <span>ladialisis.com,</span> se reserva el derecho de modificar
          cualquier tipo de información que pudiera aparecer en el sitio web,
          sin que exista obligación de preavisar o poner en conocimiento de los
          usuarios dichas obligaciones, entendiéndose como suficiente con la
          publicación en el sitio web de Hemoladialisis.com.com<br /><br />
          <span> DATOS IDENTIFICATIVOS</span><br />
          Nombre: José Luis Díaz García <br />
          Actividad: Salud <br>
          Teléfono: 691949043 <br />
          Domicilio: Apartado de Correos nº4, <br />
          Oficina de Correos Móstoles OP <br />
          Avda de Portugal 49 <br />
          28931 Móstoles (Madrid) <br /><br />
          e-mail: enfermeriaendifusion@ladialisis.com<br /><br />
          
          <span> OBJETO</span><br />
          A través del Sitio Web, se pretende poner a disposición del usuario
          información sobre la temática desarrollada en el blog relacionada con
          el sector de actividad del responsable.<br /><br />

          <span> MARCO NORMATIVO</span><br />

          La actividad de esta web se encuentra sujeta al marco legal español y
          europeo, concretamente a las siguientes normas: <br /><br />
          Reglamento General de Protección de Datos (RGPD) (UE) 2016/679, de 27
          de abril, que regula el tratamiento de datos personales en los países
          de la Unión Europea. <br />
          Ley Orgánica 3/2018, de 5 de diciembre, sobre Protección de Datos
          Personales y Garantía de los Derechos Digitales (LOPD y GDD), norma de
          ámbito regional (aplicable a España), y que define y amplía muchos de
          los conceptos y derechos presentes en el RGPD. <br />
          Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y del Comercio Electrónico (LSSICE), norma que afecta a
          aquellas webs que, de alguna forma, realicen actividades económicas
          mediante medios electrónicos, como es el caso de esta web.
          <br /><br />

          <span>PROPIEDAD INDUSTRIAL E INTELECTUAL</span><br /><br />

          El sitio web, incluyendo a título enunciativo, pero no limitativo su
          programación, edición, compilación y demás elementos necesarios para
          su funcionamiento, los diseños, logotipos, texto y/o gráficos son
          propiedad de “ladialisis.com” o, en su caso, dispone de licencia o
          autorización expresa por parte de los autores. <br />
          <span
            >Todos los contenidos del sitio web se encuentran debidamente
            protegidos por la normativa de propiedad intelectual e
            industrial</span
          >
          , así como inscritos en los registros públicos correspondientes, y no
          se permite la reproducción y/o publicación, total o parcial, del sitio
          web, ni su tratamiento informático, su distribución, difusión,
          modificación o transformación, sin el permiso previo y por escrito del
          mismo. <br />
          “ladialisis.com” velará por el cumplimiento de las anteriores
          condiciones, así como por la debida utilización de los contenidos
          presentados en sus páginas web, ejercitando todas las acciones civiles
          y penales que le correspondan en el caso de infracción o
          incumplimiento de estos derechos por parte del Usuario. Los diseños,
          logotipos, texto y/o gráficos ajenos a “ladialisis.com” y que pudieran
          aparecer en el sitio web, pertenecen a sus respectivos propietarios,
          siendo ellos mismos responsables de cualquier posible controversia que
          pudiera suscitarse respecto a los mismos. En todo caso,
          “ladialisis.com” cuenta con la autorización expresa y previa por parte
          de los mismos. <br />
          “ladialisis.com” reconoce a favor de sus titulares los
          correspondientes derechos de propiedad industrial e intelectual, no
          implicando su sola mención o aparición en el sitio web la existencia
          de derechos o responsabilidad alguna del Responsable sobre los mismos,
          como tampoco respaldo, patrocinio o recomendación por parte del mismo.
          <br /><br />

          <span>5. OBLIGACIONES Y RESPONSABILIDADES </span><br /><br />

          Toda persona que acceda a este sitio web asume el papel de Usuario,
          comprometiéndose a la observancia y cumplimiento riguroso de las
          condiciones aquí dispuestas, así como a cualquiera otra disposición
          legal que fuera de aplicación. “ladialisis.com” no se hará responsable
          de los daños y perjuicios, propios o a terceros, producidos por el uso
          inadecuado de este sitio web por parte del Usuario. <br />
          El contenido de esta web, por tanto, no puede ser considerado, en
          ningún caso, sustitutivo de asesoramiento legal.
          <br />
          “ladialisis.com” se reserva el derecho a modificar cualquier tipo de
          información que pudiera aparecer en la web, en cualquier momento y sin
          previo aviso, sin que exista obligación de preavisar o poner en
          conocimiento de los Usuarios dichas obligaciones, entendiéndose como
          suficiente la publicación en el presente sitio web. <br />
          Este sitio web ha sido revisado y probado para que funcione
          correctamente de manera ininterrumpida. No obstante, “ladialisis.com”
          no descarta la posibilidad de que existan ciertos errores de
          programación, falta de disponibilidad puntual (por ejemplo, caídas del
          servidor, o mantenimiento del mismo) o que acontezcan causas de fuerza
          mayor, catástrofes naturales, huelgas, o circunstancias semejantes que
          hagan imposible el acceso temporal a la página web. <br />
          De igual forma, “ladialisis.com” no puede garantizar el funcionamiento
          ininterrumpido o totalmente libre de errores de esta web, ni se
          responsabiliza de los virus que tengan su origen en una transmisión
          telemática infiltrados por terceros generados con la finalidad de
          obtener resultados negativos para un sistema informático. <br />

          <span>El Usuario se compromete a:</span>
          1. Hacer un uso adecuado y lícito del Espacio Web así como de los
          contenidos y servicios, de conformidad con: <br />
          (i) la legislación aplicable en cada momento; <br />
          (ii) las Condiciones Generales de Uso del Espacio Web; <br />
          (iii) la moral y buenas costumbres generalmente aceptadas <br />
          (iv) el orden público.<br /><br />
          2. Proveerse de todos los medios y requerimientos técnicos que se
          precisen para acceder al Espacio Web.<br /><br />
          3. Facilitar información veraz al cumplimentar con sus datos de
          carácter personal los formularios contenidos en el Espacio Web y a
          mantenerlos actualizados en todo momento de forma que responda, en
          cada momento, a la situación real del Usuario. <br />El Usuario será
          el único ladialisis.com de las manifestaciones falsas o inexactas que
          realice y de los perjuicios que cause a EL ladialisis.com o a terceros
          por la información que facilite. No obstante lo establecido en el
          apartado anterior el Usuario deberá asimismo abstenerse de:<br /><br />
          1. Hacer un uso no autorizado o fraudulento del Espacio Web y/o de los
          contenidos con fines o efectos ilícitos, prohibidos en las presentes
          Condiciones Generales de Uso, lesivos de los derechos e intereses de
          terceros, o que de cualquier forma puedan dañar, inutilizar,
          sobrecargar, deteriorar o impedir la normal utilización de los
          servicios o los documentos, archivos y toda clase de contenidos
          almacenados en cualquier equipo informático.<br /><br />
          2. Acceder o intentar acceder a recursos o áreas restringidas del
          Espacio Web, sin cumplir las condiciones exigidas para dicho acceso.
          3. Provocar daños en los sistemas físicos o lógicos del Espacio Web,
          de sus proveedores o de terceros.<br /><br />
          4. Introducir o difundir en la red virus informáticos o cualesquiera
          otros sistemas físicos o lógicos que sean susceptibles de provocar
          daños en los sistemas físicos o lógicos de EL ladialisis.com,
          proveedores o de terceros.<br /><br />
          5. Intentar acceder, utilizar y/o manipular los datos de EL
          ladialisis.com, terceros proveedores y otros Usuarios.<br /><br />
          6. Reproducir o copiar, distribuir, permitir el acceso del público a
          través de cualquier modalidad de comunicación pública, transformar o
          modificar los contenidos, a menos que se cuente con la autorización
          del titular de los correspondientes derechos o ello resulte legalmente
          permitido.<br /><br />
          7. Suprimir, ocultar o manipular las notas sobre derechos de propiedad
          intelectual o industrial y demás datos identificativos de los derechos
          de la empresa o de terceros incorporados a los contenidos, así como
          los dispositivos técnicos de protección o cualesquiera mecanismos de
          información que puedan insertarse en los contenidos.<br /><br />
          8. Obtener e intentar obtener los contenidos empleando para ello
          medios o procedimientos distintos de los que, según los casos, se
          hayan puesto a su disposición a este efecto o se hayan indicado
          expresamente en las páginas web donde se encuentren los contenidos o,
          en general, de los que se empleen habitualmente en Internet por no
          entrañar un riesgo de daño o inutilización del Espacio web y/o de los
          contenidos.<br /><br />
          9. En particular, y a título meramente indicativo y no exhaustivo, el
          Usuario se compromete a no transmitir, difundir o poner a disposición
          de terceros informaciones, datos, contenidos, mensajes, gráficos,
          dibujos, archivos de sonido y/o imagen, fotografías, grabaciones,
          software y, en general, cualquier clase de material que: <br />• De
          cualquier forma sea contrario, menosprecie o atente contra los
          derechos fundamentales y las libertades públicas reconocidas
          constitucionalmente, en los Tratados Internacionales y en el resto de
          la legislación vigente.<br />• Induzca, incite o promueva actuaciones
          delictivas, denigratorias, difamatorias, violentas o, en general,
          contrarias a la ley, a la moral, a las buenas costumbres generalmente
          aceptadas o al orden público.<br />• Induzca, incite o promueva
          actuaciones, actitudes o pensamientos discriminatorios por razón de
          sexo, raza, religión, creencias, edad o condición.<br />• Incorpore,
          ponga a disposición o permita acceder a productos, elementos, mensajes
          y/o servicios delictivos, violentos, ofensivos, nocivos, degradantes
          o, en general, contrarios a la ley, a la moral y a las buenas
          costumbres generalmente aceptadas o al orden público. <br />Induzca o
          pueda inducir a un estado inaceptable de ansiedad o temor.<br />•
          Induzca o incite a involucrarse en prácticas peligrosas, de riesgo o
          nocivas para la salud y el equilibrio psíquico.<br />• Se encuentra
          protegido por la legislación en materia de protección intelectual o
          industrial perteneciente a la sociedad o a terceros sin que haya sido
          autorizado el uso que se pretenda realizar.<br />• Sea contrario al
          honor, a la intimidad personal y familiar o a la propia imagen de las
          personas.<br />• Constituya cualquier tipo de publicidad.<br />•
          Incluya cualquier tipo de virus o programa que impida el normal
          funcionamiento del Espacio Web.<br /><br />
          Si para acceder a algunos de los servicios y/o contenidos del Espacio
          Web, se le proporcionara una contraseña, se obliga a usarla de manera
          diligente, manteniéndola en todo momento en secreto. En consecuencia,
          será ladialisis.com de su adecuada custodia y confidencialidad,
          comprometiéndose a no cederla a terceros, de manera temporal o
          permanente, ni a permitir el acceso a los mencionados servicios y/o
          contenidos por parte de personas ajenas. Igualmente, se obliga a
          notificar a la sociedad cualquier hecho que pueda suponer un uso
          indebido de su contraseña, como, a título enunciativo, su robo,
          extravío o el acceso no autorizado, con el fin de proceder a su
          inmediata cancelación. <br />
          En consecuencia, mientras no efectúe la notificación anterior,
          ladialisis.com quedará eximida de cualquier responsabilidad que
          pudiera derivarse del uso indebido de su contraseña, siendo de su
          responsabilidad cualquier utilización ilícita de los contenidos y/o
          servicios del Espacio Web por cualquier tercero ilegítimo. Si de
          manera negligente o dolosa incumpliera cualquiera de las obligaciones
          establecidas en las presentes Condiciones Generales de Uso, responderá
          por todos los daños y perjuicios que de dicho incumplimiento pudieran
          derivarse para ladialisis.com.<br /><br />

          ladialisis.com excluye cualquier responsabilidad por los daños y
          perjuicios de toda naturaleza que pudieran deberse a la mala
          utilización de los servicios de libre disposición y uso por parte de
          los Usuarios de Espacio Web. Asimismo queda exonerado de cualquier
          responsabilidad por el contenido e informaciones que puedan ser
          recibidas como consecuencia de los formularios de recogida de datos,
          estando los mismos únicamente para la prestación de los servicios de
          consultas y dudas. Por otro lado, en caso de causar daños y perjuicios
          por un uso ilícito o incorrecto de dichos servicios, podrá ser el
          Usuario reclamado por los daños o perjuicios causados.<br />
          Usted mantendrá a ladialisis.com indemne frente a cualesquiera daños y
          perjuicios que se deriven de reclamaciones, acciones o demandas de
          terceros como consecuencia de su acceso o uso del Espacio Web.
          Asimismo, usted se obliga a indemnizar frente a cualesquiera daños y
          perjuicios, que se deriven del uso por su parte de “robots”,
          “spiders”, “crawlers” o herramientas similares empleadas con el fin de
          recabar o extraer datos o de cualquier otra actuación por su parte que
          imponga una carga irrazonable sobre el funcionamiento del Espacio
          Web.<br /><br />

          <span>HIPERVÍNCULOS</span><br /><br />

          “ladialisis.com” puede poner a disposición del Usuario enlaces u otros
          elementos que permiten el acceso hacia otros sitios web pertenecientes
          a terceros. No comercializamos los productos y/o servicios de dichas
          páginas enlazadas, ni asumimos ningún tipo de responsabilidad sobre
          las mismas, ni sobre la información contenida en ellas, ni su
          veracidad o licitud,ni de cualesquiera efectos que pudieran derivarse.
          <br />
          En todo caso, “ladialisis.com” manifiesta que procederá a la retirada
          inmediata de cualquier contenido que pudiera contravenir la
          legislación nacional o internacional, la moral o el orden público,
          procediendo a la retirada inmediata de la redirección a dicho sitio
          web, poniendo en conocimiento de las autoridades competentes el
          contenido en cuestión. <br />
          Por su parte, el Responsable de esta web forma parte de los programas
          de afiliación de algunos productos y/o servicios de marcas
          relacionadas con los contenidos publicados en el mismo. <br />
          “ladialisis.com” asume el compromiso de realizar enlaces de afiliación
          exclusivamente con productos y/o servicios de los cuales tiene
          conocimiento de la calidad de los mismos. <br />
          Sin embargo, no comercializamos los productos y/o servicios de las
          páginas referidas (afiliados), ni asumimos ningún tipo de
          responsabilidad sobre las mismas, ni sobre la información contenida en
          ellas, ni su veracidad o licitud, ni de cualesquiera efectos que
          pudieran derivarse. <br />
          En caso de que el usuario sufra cualquier tipo problema con alguno de
          los productos o servicios recomendados, “ladialisis.com” no asume
          responsabilidad legal y de ningún otro tipo, aunque procederá a la
          retirada inmediata de cualquier contenido que pudiera contravenir la
          legislación nacional o internacional, la moral o el orden público,
          procediendo a la retirada inmediata de la redirección a dicho sitio
          web, poniendo en conocimiento de las autoridades competentes el
          contenido en cuestión.
          <br /><br />

          <span> PROTECCIÓN DE DATOS</span><br />br Para utilizar algunos de los
          Servicios, el Usuario debe proporcionar previamente ciertos datos de
          carácter personal. ladialisis.com tratará automatizadamente estos
          datos y aplicará las correspondientes medidas de seguridad, todo ello
          en cumplimiento del RGPD, LOPDGDD y LSSI. El Usuario puede acceder a
          la política seguida en el tratamiento de los datos personales, así
          como el establecimiento de las finalidades previamente establecidas,
          en las condiciones definidas en la Política de Privacidad.<br /><br />

          <span>9.COOKIES</span>
          <br /><br />
          ladialisis.com se reserva el derecho de utilizar la tecnología
          “cookie” en el Espacio Web, a fin de reconocerlo como Usuario
          frecuente y personalizar el uso que realice del Espacio Web mediante
          la preselección de su idioma, o contenidos más deseados o
          específicos.<br />
          Las cookies recopilan la dirección IP del usuario siendo Google
          ladialisis.com del tratamiento de esta información. Las cookies son
          ficheros enviados a un navegador, por medio de un servidor Web, para
          registrar la navegación del Usuario en el Espacio Web, cuando el
          Usuario permita su recepción. Si usted lo desea puede configurar su
          navegador para ser avisado en pantalla de la recepción de cookies y
          para impedir la instalación de cookies en su disco duro. Por favor
          consulte las instrucciones y manuales de su navegador para ampliar
          esta información.<br /><br />
          Gracias a las cookies, resulta posible que se pueda reconocer el
          navegador del ordenador utilizado por el Usuario con la finalidad de
          facilitar contenidos y ofrecer las preferencias de navegación u
          publicitarias que el Usuario, a los perfiles demográficos de los
          Usuarios así como para medir las visitas y parámetros del tráfico,
          controlar el progreso y número de entradas.<br /><br />

          <span>10.INTELIGENCIA ARTIFICIAL (SINDICAPT u OTRAS)</span>
          <br /><br />
          ladialisis.com se reserva el derecho de utilizar la tecnología
          de Inteligencia Artificial en el Espacio Web, para el desarrollo de herramientas
          que faciliten la vida de las personas, pudiendo ser éstas herramientas de consulta,
          generación de documentos o cualquier otra funcionalidad que ladialisis.com crea 
          de utilidad para tal fín. Comentadas herramientas son meramente orientativas,
          y ladialisis.com y su creador no se responsabilizan de un mal uso de estas.
          Por ello se recomienda contrastar la información generada con profesionales 
          del sector correspondiente y revisar posibles actualizaciones en la documentación
          oficial con la cual se entrenan estas herramientas con IA<br/>
          <br/><br/>

          <span>RESOLUCIÓN DE CONTROVERSIAS. LEY APLICABLE Y JURISDICCIÓN</span
          ><br />

          El idioma de redacción e interpretación de este aviso legal es el
          español. La relación entre “ladialisis.com” y Usuario se rige en todos
          y cada uno de sus extremos por la ley española, a la que se someten
          expresamente ambas partes ante cualquier controversia o cuestión
          relacionada con el presente sitio web o las actividades en él
          desarrolladas. <br />
          Por su parte, en el caso de que el usuario tenga su domicilio fuera de
          España, “ladialisis.com” y Usuario se someten, con renuncia expresa a
          cualquier otro fuero, a los Juzgados y Tribunales competentes de
          España.
          <br /><br />
        </p>
      </div>
    </article>
  </section>
</template>

<script setup>
import { onBeforeRouteUpdate } from 'vue-router';

const pageMeta = {
  title: 'Aviso legal',
  description: 'Aviso legal',
  keywords: 'dialisis, dialisis peritoneal, sintomas, tratamientos, nutricion en dialisis, hiperpotasemia, ¿que es la dialisis?, sodio, fosforo'
};

// Actualizar las metaetiquetas y el título antes de cambiar de ruta
onBeforeRouteUpdate((to, from, next) => {
  updatePageMeta(to.meta);
  next();
});

// Función para actualizar las metaetiquetas y el título
function updatePageMeta(meta) {
  const head = document.head || document.getElementsByTagName('head')[0];

  // Actualizar el título
  const title = document.title;
  document.title = meta.title || title;

  // Actualizar las metaetiquetas
  const metaTags = head.getElementsByTagName('meta');
  for (let i = 0; i < metaTags.length; i++) {
    const tag = metaTags[i];
    if (tag.getAttribute('name') === 'description') {
      tag.content = meta.description || '';
    }
    if (tag.getAttribute('name') === 'keywords') {
      tag.content = meta.keywords || '';
    }
    if (tag.getAttribute('name') === 'robots') {
      tag.content = 'noindex'; // Agregar la etiqueta noindex
    }
  }

  // Si la etiqueta robots no existe, agregarla al head
  const robotsTag = document.createElement('meta');
  robotsTag.name = 'robots';
  robotsTag.content = 'noindex';
  head.appendChild(robotsTag);
}

// Configurar las metaetiquetas y el título inicialmente
updatePageMeta(pageMeta);
</script>

<style scoped></style>
