<template>
    <footer class="text-center text-lg-start bg-light text-muted ">
  <!-- Section: Social media -->
     <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    
    <div>
      
      <a title="enlace a instagram" target="_blank" href="https://www.instagram.com/matedevloop/" class="me-4 text-reset" tabindex="5">
        <v-icon name="bi-instagram" scale="2" fill="red"/>
      </a>
      <a title="enlace a linkedin" target="_blank" href="https://www.linkedin.com/in/jose-luis-diaz-garcia-aa0887229/" class="me-4 text-reset" tabindex="6">
        <v-icon name="bi-linkedin" scale="2" fill="red"/>
      </a> 
      <a title="enlace a github" target="_blank" href="https://github.com/MateMaki33" class="me-4 text-reset" tabindex="7">
        <v-icon name="bi-github" scale="2" fill="red"/>      
      </a>
    
    </div>
    <!-- Right -->
  </section>
  <!-- Section: Social media -->

  <!-- Section: Links  -->
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Legal
          </h6>
          <p>
            <router-link title="enlace a aviso legal" to="/aviso_legal" class="text-reset" tabindex="9">Aviso Legal</router-link></p>
          <p>
            <router-link title="enlace a politica de privacidad" to="/politica_de_privacidad" class="text-reset" tabindex="11">Política de privacidad</router-link></p>
          <p>
            <router-link title="enlace a cookies" to="/cookies" class="text-reset" tabindex="13">Política de cookies</router-link></p>
          
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-4">
            Enlaces útiles
          </h6>
          <p>
            <a title="enlace a SEDEN" href="https://www.seden.org/" target="_blank" class="text-reset" tabindex="10">SEDEN</a>
          </p>
          <p>
            <a title="enlace a ALCER" href="https://alcer.org/" target="_blank" class="text-reset" tabindex="12">ALCER</a>
          </p>
          <p>
            <a title="enlace a SENEFRO" href="https://www.senefro.org/modules.php?name=home&lang=ES" target="_blank" class="text-reset" tabindex="14">SENEFRO</a>
          </p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    © 2023 Copyright:
    Desarrollado por <a title="enlace a linkedin2" target="_blank" href="https://www.linkedin.com/in/jose-luis-diaz-garcia-aa0887229/">José Luis Díaz</a>  | matedeveloop | @2023 Todos los derechos reservados  
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
</template>

<script setup>
</script>

<style scoped>
a{
    text-decoration: none;
}
footer{
  margin-top: 200px;
}
</style>