
const sintomas = [

  { nombre: "Dolor de cabeza" },
  { nombre: "Mareo" },
  { nombre: "Nauseas" },
  { nombre: "Calambres" },
  { nombre: "Hormigueo" },
  { nombre: "Sofoco" }

];

export default sintomas